<template>
  <div class="exam-record">
    <div class="exam-record-cont">
      <div class="sub-tab">
        <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
      </div>
      <div v-if="recordData.length">
        <div class="search">
          <div class="search-item">
            <div class="name">
              分类:
            </div>
            <div class="cont">
              <div class="values">
                <div :class="curCategoryId == item.id ? 'active' : ''" v-for="(item,fIndex) in categoryList" :key="item.id + 'category'" @click="changeSearch(item)">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <el-table :data="recordData" :loading="recordLoading" border size="small" :header-cell-style="{backgroundColor: '#F8F8F9',color: '#666',fontWeight: 'bold'}">
          <el-table-column label="序号" prop="id" fixed="left"></el-table-column>
          <el-table-column label="名称" show-overflow-tooltip fixed="left" min-width="200">
            <template v-slot="{row}">
              <router-link style="color:#2d8cf0;"
              :to="{path:'/examine/trainingcamp/exam-detail',query:{
                id: row.train.id,
                tab: 'result'}}">
                  {{row.train.name}}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="生成对象" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.train.generate_type == 0?row.train.generate_category.name:row.train.generate_type == 1? row.train.generate_map.name:row.train.generate_type == 2?row.train.generate_group.name:""}}
            </template>
          </el-table-column>
          <el-table-column label="总题量" prop="train.data_count"></el-table-column>
          <el-table-column label="总分" prop="train.total_score"></el-table-column>
          <el-table-column label="客观题分数" prop="auto_check_score" min-width="100"></el-table-column>
          <el-table-column label="主观题分数" prop="hand_check_score" min-width="100"></el-table-column>
          <el-table-column label="及格分" prop="train.pass_score"></el-table-column>
          <el-table-column label="答题数" prop="have_finished"></el-table-column>
          <el-table-column label="答对" prop="right_count"></el-table-column>
          <el-table-column label="得分" prop="score"></el-table-column>
          <el-table-column label="用时" prop="use_time"></el-table-column>
          <el-table-column label="状态">
            <template v-slot="{row}">
              {{ trainUserStatuses[row.status] }}
            </template>
          </el-table-column>
          <el-table-column label="交卷时间" min-width="120" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ util.timeFormatter(new Date(+row.finish_time * 1000), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
            <template v-slot="{row}">
              <el-button size="small" :type="row.train.status!= 3 ? 'info' : 'primary'" :disabled="row.train.status!= 3"  @click="checkResult(row)">查看结果</el-button>
              <el-button size="small" type="primary" @click="toReport(row)">查看报告</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page" >
          <Page :total="totalRecord" @on-change="changeRecordPage" @on-page-size-change="changeRecordPageSize" :current="recordPage" show-total show-sizer show-elevator></Page>
        </div>
      </div>

      <div class="no-data" v-if="!recordData.length && !recordLoading">
        <img src="../../../../assets/images/new_icon/nodata.png" width="158" height="82" />
        <p>暂时还没有考试试卷哦~</p>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "examRecord",
  data(){
    return{
      util,
      tabList:[
        // {
        //   name:'教材题库',
        //   id:'1'
        // },
        {
          name:'认证题库',
          id:'0'
        },
        {
          name:'课程题库',
          id:'4'
        }
      ],
      curTab:{
        name:'认证题库',
        id:'0'
      },
      recordData:[],
      recordLoading:true,
      totalRecord:0,
      recordPage:1,
      recordPageSize:10,
      categoryList:[],
      curCategoryId:'0',
      categoryType:'',
    }
  },
  props:{
    from:{
      type:String,
      default:''
    }
  },
  created(){
    this.categoryType = this.$route.query.categoryType || '';
    if(this.categoryType){
      this.curTab = this.tabList.filter((item)=>{
        return item.id == this.categoryType;
      })[0]
    }
    this.getRecordList();
  },
  methods:{
    changeTab(data){
      this.curTab = data;
      this.curCategoryId = '0';
      this.getRecordList();
    },
    getRecordList(){
      this.recordLoading = true;
      let params = {
        action:'myTrainList',
        page:this.recordPage,
        pageSize:this.recordPageSize,
        type:1,
        exam_type:2,  //1自测模考 2模考
        category_id:this.curCategoryId,
        category_type:this.curTab.id,
      };
      this.api.dataset.trainExecute(params).then((res)=>{

        this.recordLoading = false;
        if(!res.list.length && this.recordPage > 1){
          this.recordPage = this.recordPage - 1;
          this.getRecordList();
          return;
        }
        this.recordData = res.list;
        this.totalRecord = Number(res.count);
        this.trainUserStatuses = res.trainUserStatuses;//trainuser.status
        this.generatetypesObj = res.generate_types;

        this.categoryList =[
          {
            name:'全部',
            id:0
          },
          ...res.category_list
        ]
      }).catch((err)=>{
        this.recordLoading = false;
      })
    },
    changeSearch(data){
      this.curCategoryId = data.id;
      this.getRecordList();
    },
    changeRecordPage(page){
      this.recordPage = page;
      this.getRecordList();
    },
    changeRecordPageSize(pageSize){
      this.recordPageSize = pageSize;
      this.getRecordList();
    },
    checkResult(data){
      if(this.from == 'examine'){
        this.$router.push({
          path:'/examine/trainingcamp/exam',
          query:{
            id:data.train_id || data.id,
            isResult:true,
            isSelf:true
          }
        })
      }else{
        this.$router.push({
          path:'/trainingcamp/exam',
          query:{
            id:data.train_id || data.id,
            isResult:true,
            isSelf:true
          }
        })
      }

    },
    toReport(data){
      if(this.from == 'examine'){
        this.$router.push({
          path:'/examine/trainingcamp/train-report',
          query:{
            id:data.id
          }
        })
      }else{
        this.$router.push({
          path:'/trainingcamp/train-report',
          query:{
            id:data.id
          }
        })
      }

    },
  }
}
</script>

<style scoped lang="scss">
.exam-record{
  .exam-record-cont{
    .sub-tab{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-right: 10px;
        width: 100px;
        height: 42px;
        background: #F6F7FA;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 42px;
      }
      .active{
        background: #4A79FF;
        color: #FFFFFF;
      }
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
    .search{
      margin-top: 20px;
      margin-bottom: 20px;
      .search-item{
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;

        .name{
          padding: 5px 0;
          width: 75px;
          text-align: right;
        }
        .cont{
          margin-left: 15px;
          flex: 1;
          display: flex;
          justify-content: space-between;

          .values{
            display: flex;
            justify-content: flex-start;
            flex-flow: wrap;
            transition: max-height 0.3s linear;
            overflow: hidden;

            >div{
              padding: 5px 10px;
              //margin: 0 10px 10px 5px;
              cursor: pointer;
            }
            >div.active{
              background-color: #4A79FF;
              color:#FFFFFF;
              border-radius: 4px;
            }
          }
          .more{
            padding: 5px 0;
            width: 80px;
            text-align: right;
            cursor: pointer;
            .more-icon{
              transition: transform 0.3s linear;
            }
          }
        }
      }
    }
    .no-data{
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      >p{
        margin-top: 35px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
