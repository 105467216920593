<template>
  <div class="my-exam">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="my-exam-cont">
      <div class="top">
        <div class="tag">
          <div class="item" :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">
            {{item.name}}
          </div>
        </div>
      </div>
      <component :is="curTab.componentName" from="examine"></component>
    </div>
  </div>
</template>

<script>
import Exam from './components/stuExam/exam';
import Record from './components/stuExam/record';
export default {
  name: "examEnter",
  data(){
    return{
      tabList:[
        {
          name:'考试记录',
          id:1,
          tab:'record',
          componentName:'Record'
        },
      ],
      curTab:{
        name:'考试记录',
        id:1,
        tab:'record',
        componentName:'Record'
      },
      tab:'',
    }
  },
  components:{
    Exam,
    Record,
  },
  mounted(){
    console.log(this.$route.query,'this.$route.querythis.$route.query')
    // this.tab = this.$route.query.tab || '';
    // console.log(this.tab,'this.tab')
    // if(this.tab){
    //   this.curTab = this.tabList.filter((item)=>{
    //     return item.tab == this.tab;
    //   })[0];
    // }

  },
  methods:{
    // changeTab(data){
    //   this.curTab = data;
    //   this.$router.push({
    //     name: 'my-exam',
    //     params: {
    //       id: this.$route.params.id,
    //       tab: data.tab,
    //     }
    //   });
    // },

  }
}
</script>

<style scoped lang="scss">
.my-exam{
  padding: 20px;

  .my-exam-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
  .top{
    margin-bottom: 20px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;

    .tag{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      height: 100%;
      font-size: 16px;
      color: #666666;


      >div{
        margin-right: 30px;
        cursor:pointer;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
      }
      >div.active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }

    .tag-r{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      text-align: right;

      .tag-r-input{
        margin-right: 10px;
      }
    }
  }

}
</style>
