<template>
<div class="stu-exam">
  <div class="sub-tab">
    <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
  </div>
  <div class="stu-exam-item" v-for="item in dataList" :key="item.id">
    <div class="stu-exam-item-l">
      <div class="stu-exam-item-l-tit" >{{ item.name }}<span v-if="item.my_join_status == 1">进行中</span></div>
      <div class="stu-exam-item-l-time">考试时间：{{ util.timeFormatter(new Date(+item.start_time * 1000), 'yyyy.MM.dd hh:mm') }} - {{ util.timeFormatter(new Date(+item.end_time * 1000), 'yyyy.MM.dd hh:mm') }}</div>
      <div class="stu-exam-item-l-info">考试时长：{{ item.timelong }}分钟<span>|</span>总分数：{{ item.total_score }}分<span>|</span>及格分数：{{ item.pass_score }}分</div>
    </div>
    <div>
      <el-button type="primary" @click="goExamInfo(item)">考试详情</el-button>
      <el-button type="primary" @click="goExam(item)" v-if="item.my_joined_count == 0 && (item.status == 1 || item.status == 2)">去考试</el-button>

<!--      <el-button type="info" :disabled="item.my_joined_count > 0 && item.status != 3" v-if="item.my_joined_count > 0 && item.status < 3">查看结果</el-button>-->
<!--      <el-button type="primary"  @click="goResult(item)" v-if="item.my_joined_count > 0 && item.status == 3">查看结果</el-button>-->
    </div>
  </div>
  <div class="page" v-if="dataList.length">
    <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
  </div>
  <div class="no-data" v-if="!dataList.length && !loading">
    <img src="../../../../assets/images/new_icon/nodata.png" width="158" height="82" />
    <p>暂时还没有考试试卷哦~</p>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "examView",
  data(){
    return{
      dataList:[],
      total:0,
      page:1,
      util:util,
      loading:true,
      tabList:[
        {
          name:'课程题库',
          id:'4'
        },
        {
          name:'认证题库',
          id:'0'
        },
        // {
        //   name:'教材题库',
        //   id:'1'
        // },
        //

      ],
      curTab:{
        name:'课程题库',
        id:'4'
      },
    }
  },
  created(){
    this.getList();
  },
  methods:{
    changeTab(data){
      this.curTab = data;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        action:'trainList',
        type:'1',//类型0练习1考试2学习
        page:this.page,
        pageSize:this.pageSize,
        category_type:this.curTab.id
      };

      this.api.dataset.trainExecute(params).then((res)=>{

        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.dataList = res.list;
        this.total = Number(res.count);
        // this.typeObj = res.types;
        // this.trainStatuses = res.statuses;
        // this.generatetypesObj = res.generate_types;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    goExamInfo(data){
      this.$router.push({
        path: '/examine/trainingcamp/exam-detail',
        query: {
          id: data.id,
          tab: 'result',
          roomId:data.live_room.id
        }
      })
    },
    goExam(data){
      if(data.my_join_status == 1){
        this.startExam(data)
      }else{
        this.goPreExam(data)
      }
    },
    goPreExam(data){
      this.$router.push({
        path:'/trainingcamp/pre-exam',
        query:{
          id:data.id,
        }
      })
    },
    startExam(data){
      this.$router.push({
        path:'/exam',
        query:{
          id:data.id,
        }
      })
    },
    goResult(data){
      this.$router.push({
        path:'/trainingcamp/exam',
        query:{
          id:data.train_id || data.id,
          isResult:true
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.stu-exam{
  font-size: 14px;
  .sub-tab{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-right: 10px;
      width: 100px;
      height: 42px;
      background: #F6F7FA;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      line-height: 42px;
    }
    .active{
      background: #4A79FF;
      color: #FFFFFF;
    }
  }
  .stu-exam-item{
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:#F6F7FA;
    .stu-exam-item-l-tit{
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        margin-left: 10px;
        background: #FE9200;
        border-radius: 4px;
        padding: 4px 10px;
        color:#FFFFFF;
        font-size: 12px;
      }
    }
    .stu-exam-item-l-time{
      margin: 10px 0 6px 0;
      color:#666666;
    }
    .stu-exam-item-l-info{
      color: #999999;
      >span{
        padding: 0 10px;
      }
    }
  }
  .page{
    text-align: right;
  }
  .no-data{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
